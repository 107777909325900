import React, { useEffect, useState, useRef, useMemo } from "react";
import "./ModalTask.scss";
import InfoTask from "./components/InfoTask/InfoTask";
import ProjectInfo from "./components/ProjectInfo/ProjectInfo";
import ChatMini from "../ChatMini/ChatMini";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import HistoryChanges from "../HistoryChanges/HistoryChanges";
import Button from "../Button/Button";
import { useModal } from "../../hooks/useModal";
import SubtaskList from "../SubtaskList/SubtaskList";
import Select from "../Select/Select";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import { setData } from "../../store/reducers/modalsReduces";
import { useDispatch } from "react-redux";
import { asyncEditStatusTask, asyncGetTask } from "../../store/reducers/tasksReducer";
import Screen from "../Screen/Screen";
import Icons from "../Icons/Icons";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useSelector } from "react-redux";
import { useChangeIV } from "../../hooks/useChangeIV";
import { toast } from "react-toastify";
import ChatRow from "../ChatRow/ChatRow";

function ModalTask(props) {
  const {
    restrictSelection, // ограничивает взаимодействие с задачей
    modal = false,
    handleChange,
  } = props;

  const dispatch = useDispatch();

  const ref = useRef();

  const { getInfo, changeModalVisible } = useModal();

  const { userData } = useSelector((state) => state.user);

  const { sizeModal, viewModal } = useSelector((state) => state.settings);

  const info = getInfo("infoTask", "data");

  const { IV, changeIV } = useChangeIV({
    rejectedComment: "",
  });

  const [rejectedComment, setRejectedComment] = useState(false);

  const [loading, setLoading] = useState(false);
  const [visibleChat, setVisibleChat] = useState(false);
  const [permission, setPermission] = useState({
    assigned: false,
    rejected: false,
    atWork: false,
    overdue: false,
    onVerification: false,
    completed: false,
    select: false,
    edit: false,
  });

  useOnClickOutside(ref, () => setVisibleChat(false));

  const { options, changeOptions } = useChangeOptions({
    select: {
      options: [
        { label: "Редактировать", value: "edit" },
        { label: "Копировать", value: "copy" }, // разрешить исполнителю и добавить делегирование
        { label: "Делегировать", value: "delegation" },
        { label: "Отложить", value: "putAside" },
        { label: "Удалить", value: "remove" },
      ],
      value: { label: "Ещё", value: "other" },
    },
  });

  const onEvent = (event) => {
    if (event === "edit") {
      changeModalVisible("editTask", true, info);
    } else if (event === "remove") {
      changeModalVisible("deleteTask", true, info);
    } else if (event === "copy") {
      const dataCopy = {
        ...info,
        author: null,
      };
      changeModalVisible("copyTask", true, dataCopy);
    } else if (event === "delegation") {
      const dataDelegation = {
        ...info,
        author: null,
        executors: [],
        observers: [],
      };
      changeModalVisible("delegationTask", true, dataDelegation);
    } else if (event === "putAside") {
      changeModalVisible("putAsideTask", true, info);
    }
  };

  const getCurrentData = async () => {
    const res = await dispatch(asyncGetTask(info._id));
    if (res.error) return;
    dispatch(
      setData({
        modalName: "infoTask",
        data: res.payload,
      }),
    );
  };

  const editStatusTask = async (type) => {
    if (type === "rejected" && !IV.rejectedComment?.trim()) {
      toast.warning("Введите причину отклонения");
      return setRejectedComment(true);
    }

    setLoading(true);

    const res = await dispatch(
      asyncEditStatusTask({
        status: type,
        tasks: [info._id],
        description: IV.rejectedComment,
      }),
    );

    if (res.error) return setLoading(false);

    dispatch(
      setData({
        modalName: "infoTask",
        data: {
          status: type,
        },
      }),
    );

    setLoading(false);
    setRejectedComment(false);
    handleChange && handleChange();
  };

  const checkExecutorPositions = useMemo(() => {
    const companiesExecutors = {};

    const executors = info?.executors || [];
    const userProfiles = userData.profiles;

    const profile = {
      adminCompany: false,
      directorCompany: false,
      responsibleProject: false,
    };

    for (let i = 0; i < executors.length; i++) {
      const { company } = executors[i];
      if (company) {
        if (!companiesExecutors[company._id]) {
          companiesExecutors[company._id] = company;
        }
      }
    }

    for (let i = 0; i < userProfiles.length; i++) {
      const current = userProfiles[i];
      if (companiesExecutors[current?.company?._id]) {
        if (current.position.name === "Руководитель компании") {
          profile.directorCompany = true;
        }
        if (current.position.name === "Администратор компании") {
          profile.adminCompany = true;
        }
        if (current.position.name === "Ответственный по проекту") {
          profile.responsibleProject = true;
        }
      }
    }
    return profile;
  }, [info, userData]);

  const onCheckChangeStatus = () => {
    const { _id: userId } = userData;

    const authorId = info?.author.user?._id;
    const authorCompanyId = info?.author?.company?._id;
    const userProfiles = userData?.profiles;
    const sortCompanyProfiles =
      userProfiles?.filter((el) => el?.company?._id === authorCompanyId) || [];

    const executor = info?.executors?.find((e) => e?.user?._id === userId);

    const directorCompany = sortCompanyProfiles.find(
      (e) => e.position.name === "Руководитель компании",
    );
    // проверка на админа компании
    const adminComapany = sortCompanyProfiles.find(
      (e) => e.position.name === "Администратор компании",
    );

    // проверка на ответсенного
    const responsibleProject = sortCompanyProfiles.filter(
      (e) =>
        e.position.name === "Ответственный по проекту" && e.projects[0]?._id === info?.project?._id,
    );

    if (userData?.role === "admin") {
      // console.log("admin");
      return setPermission({
        assigned: true,
        rejected: true,
        atWork: true,
        overdue: true,
        onVerification: true,
        completed: true,
        select: true,
        edit: true,
      });
    } else if (authorId === userId && !!executor) {
      return setPermission({
        assigned: true,
        rejected: true,
        atWork: true,
        overdue: true,
        onVerification: true,
        completed: true,
        select: true,
        edit: true,
      });
    } else if (
      authorId === userId ||
      !!directorCompany ||
      !!adminComapany ||
      responsibleProject?.length > 0
    ) {
      return setPermission({
        assigned: false,
        rejected: true,
        atWork: false,
        overdue: false,
        onVerification: true,
        completed: true,
        select: true,
        edit: true,
      });
    } else if (
      executor ||
      checkExecutorPositions.adminCompany ||
      checkExecutorPositions.directorCompany ||
      checkExecutorPositions.responsibleProject
    ) {
      // проверка на исполнителя
      // console.log("исполнитель");
      return setPermission({
        assigned: true,
        rejected: false,
        atWork: true,
        overdue: true,
        onVerification: false,
        completed: false,
        select: false,
        edit: false,
      });
    } else {
      return setPermission({
        assigned: false,
        rejected: false,
        atWork: false,
        overdue: false,
        onVerification: false,
        completed: false,
        select: false,
        edit: false,
      });
    }
  };

  useEffect(() => {
    if (
      getInfo("editTask", "data") ||
      getInfo("editSubTask", "data") ||
      getInfo("deleteSubTask", "data")
    ) {
      getCurrentData();
    }
    if (getInfo("deleteTask", "data")) {
      changeModalVisible("infoTask", false, null);
    }
    if (getInfo("putAsideTask", "data")) {
      dispatch(
        setData({
          modalName: "infoTask",
          data: { end: getInfo("putAsideTask", "data").deadline },
        }),
      );
    }
    onCheckChangeStatus();
  }, []);

  useEffect(() => {
    if (permission.select) {
      changeOptions("select", "options", [
        { label: "Редактировать", value: "edit" },
        { label: "Копировать", value: "copy" },
        { label: "Делегировать", value: "delegation" },
        { label: "Отложить", value: "putAside" },
        { label: "Удалить", value: "remove" },
      ]);
    } else {
      changeOptions("select", "options", [
        // разрешить исполнителю и делегировани и копирование
        { label: "Копировать", value: "copy" },
        { label: "Делегировать", value: "delegation" },
      ]);
    }
  }, [permission.select]);

  return (
    <ModalWrapper
      title={`Просмотр задачи №${info?.number || "-"}`}
      onClose={() => changeModalVisible("infoTask", false)}
      className="modalTask"
      viewCardVisible={true}
      editBtn={{
        visible: permission?.edit,
        onClick: () => onEvent("edit"),
      }}
      btns={
        <div className="modalTask-btns-mobile">
          <div className="modalTask-btns-wrapper-mobile">
            {restrictSelection || (
              <>
                {info.status === "assigned" && permission.assigned && (
                  <>
                    <Button
                      loading={loading}
                      onClick={() => editStatusTask("rejected")}
                      title="Отклонить"
                      color="grey"
                      type="submit"
                    />
                    <Button
                      loading={loading}
                      onClick={() => editStatusTask("atWork")}
                      title="В работу"
                      color="dark"
                      type="submit"
                    />
                  </>
                )}
                {info.status === "rejected" && permission.rejected && (
                  <Button
                    loading={loading}
                    onClick={() => editStatusTask("assigned")}
                    title="Назначить"
                    color="dark"
                    type="submit"
                  />
                )}

                {info.status === "atWork" && permission.atWork && (
                  <Button
                    loading={loading}
                    onClick={() => editStatusTask("onVerification")}
                    title="На проверку"
                    color="yellow"
                    type="submit"
                  />
                )}
                {info.status === "onVerification" && permission.onVerification && (
                  <>
                    <Button
                      loading={loading}
                      onClick={() => editStatusTask("atWork")}
                      title="В работу"
                      color="dark"
                      type="submit"
                    />
                    <Button
                      loading={loading}
                      onClick={() => editStatusTask("completed")}
                      title="Выполнено"
                      color="green"
                      type="submit"
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      }
    >
      <div className="modalTask">
        <div
          className={`modalTask-main ${sizeModal ? "fullScreen" : ""} ${
            viewModal ? "rows" : "columns"
          }`}
        >
          <InfoTask
            IV={IV}
            changeIV={changeIV}
            rejectedComment={rejectedComment}
            restrictSelection={restrictSelection}
          />
          <div className="modalTask-main-right">
            <ProjectInfo viewModal={viewModal} />
          </div>
        </div>

        <div className="modalTask-btns">
          <div className="modalTask-btns-wrapper">
            {restrictSelection || (
              <>
                <Screen size={"lg"}>
                  {info.status === "assigned" && permission.assigned && (
                    <>
                      <Button
                        loading={loading}
                        onClick={() => editStatusTask("rejected")}
                        title="Отклонить"
                        color="grey"
                        type="submit"
                      />
                      <Button
                        loading={loading}
                        onClick={() => editStatusTask("atWork")}
                        title="В работу"
                        color="dark"
                        type="submit"
                      />
                    </>
                  )}
                  {info.status === "rejected" && permission.rejected && (
                    <Button
                      loading={loading}
                      onClick={() => editStatusTask("assigned")}
                      title="Назначить"
                      color="purple"
                      type="submit"
                    />
                  )}
                  {info.status === "overdue" && permission.overdue && (
                    <Button
                      loading={loading}
                      onClick={() => editStatusTask("atWork")}
                      title="В работу"
                      color="dark"
                      type="submit"
                    />
                  )}

                  {info.status === "atWork" && permission.atWork && (
                    <Button
                      loading={loading}
                      onClick={() => editStatusTask("onVerification")}
                      title="На проверку"
                      color="yellow"
                      type="submit"
                    />
                  )}
                  {info.status === "onVerification" && permission.onVerification && (
                    <>
                      <Button
                        loading={loading}
                        onClick={() => editStatusTask("atWork")}
                        title="В работу"
                        color="dark"
                        type="submit"
                      />
                      <Button
                        loading={loading}
                        onClick={() => editStatusTask("completed")}
                        title="Выполнено"
                        color="green"
                        type="submit"
                      />
                    </>
                  )}
                </Screen>
                {/* {permission.select && ( */}
                <div className="modalTask-select-change">
                  <Select
                    menuPlacement="top"
                    options={options.select.options}
                    value={options.select.value}
                    handleChange={(value) => onEvent(value.value)}
                    isClearable={false}
                  />
                </div>
                {/* )} */}
              </>
            )}
          </div>
        </div>
      </div>
      <ChatRow messages={info.comments} unReadComments={info?.unReadComments} />
      {!!info?.sub_tasks?.length && (
        <>
          <div className="hr"></div>
          <SubtaskList mode="view" tasks={info.sub_tasks} fatherId={info._id} />
        </>
      )}
      {!!info?.history?.length && <HistoryChanges history={info.history} />}
    </ModalWrapper>
  );
}

export default ModalTask;
